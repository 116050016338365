.main-navigation {
  background: $body-color;
  @include shadow;
}

.dropdown-menu.show {
  left: -7.7rem;
}

.nav-link-root {
  color: 0, 0, 0, 0.5;
}

.nav-link-item {
  color: #959595;
  margin-right: 20px;
}
